<template>
  <el-dialog
    title="编辑商品"
    :modal-append-to-body="false"
    top="20px"
    width="80%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="onCancel"
  >
    <div class="container">
      <div class="card">
        <div class="title">商品信息</div>
        <field label="商品名称：" :asterisk="true" labelWidth="198px" :validation="validation.name">
          <el-input v-model="formData.name" placeholder="请输入商品名称" style="width: 640px;"></el-input>
        </field>
        <field label="商品图片：" :asterisk="true" labelWidth="198px" :validation="validation.imgUrls">
          <el-upload
            multiple
            :action="baseUrl"
            list-type="picture-card"
            accept="image/*"
            :file-list="imgFileList"
            :headers="headers"
            :http-request="customUpload"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="handleUpImage(file)">
                  <i class="el-icon-top"></i>
                </span>
                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </field>
        <field label="上传视频：" labelWidth="198px">
          <el-upload
            :action="baseUrl"
            accept="video/*"
            list-type="text"
            :file-list="videoFileList"
            :headers="headers"
            :on-remove="handleVideoRemove"
            :on-success="onVideoSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </field>
        <div v-show="formData.videoUrls.length !== 0" class="videos">
          <video width="320" height="240" controls v-for="item in formData.videoUrls" :key="item">
            <source :src="item" type="video/mp4" />
            <source :src="item" type="video/webm" />
          </video>
        </div>

        <div class="fieldContainer">
          <field label="商品货号：" :asterisk="true" labelWidth="198px" :validation="validation.productCode">
            <el-input v-model="formData.productCode" placeholder="请输入商品货号" style="width: 220px;"></el-input>
          </field>
          <field label="成本价：" :asterisk="true" labelWidth="198px" :validation="validation.costPrice">
            <el-input v-model="formData.costPrice"></el-input>
          </field>
          <!-- 目前都按重量来计费 -->
          <!-- <field
          label="快递计费："
          :asterisk="true"
          labelWidth="198px"
          :validation="validation.unit"
        >
          <el-select v-model="formData.unit" style="width: 220px">
            <el-option label="重量" value="GRAM"></el-option>
            <el-option label="件数" value="PIECE"></el-option>
          </el-select>
        </field> -->
        </div>

        <div class="fieldContainer">
          <field label="会员价：" :asterisk="true" labelWidth="198px" :validation="validation.memberPrice">
            <el-input v-model="formData.memberPrice"></el-input>
          </field>
          <field label="超级会员价：" :asterisk="true" labelWidth="198px" :validation="validation.superMemberPrice">
            <el-input v-model="formData.superMemberPrice"></el-input>
          </field>
        </div>

        <div class="fieldContainer">
          <field label="商品单位：" :asterisk="true" labelWidth="198px" :validation="validation.showUnit">
            <el-select
              v-model="formData.showUnit"
              filterable
              allow-create
              default-first-option
              placeholder="请输入商品单位"
              style="width: 220px"
            >
              <el-option v-for="unit in basicUnit" :key="unit" :label="unit" :value="unit"></el-option>
            </el-select>
          </field>
          <field label="起订数量：" :asterisk="true" labelWidth="198px" :validation="validation.minBuyNum">
            <el-input-number v-model="formData.minBuyNum" :controls="false" :min="1" style="width: 220px;"></el-input-number>
          </field>
        </div>

        <div class="fieldContainer">
          <field label="隐藏图片：" labelWidth="198px">
            <el-input v-model="formData.imageHideArray" placeholder="图片的序号#分割如：1#4"></el-input>
          </field>
          <field label="单次限购数量：" labelWidth="198px">
            <el-input v-model="formData.limitNumber" style="width: 220px"></el-input>
          </field>
        </div>
        <div class="fieldContainer">
          <field label="包邮件数：" labelWidth="198px">
            <el-input v-model="formData.freeShipNum" :disabled="formData.showCart" style="width: 220px"></el-input>
          </field>
          <field label="是否开启评价：" labelWidth="198px">
            <el-switch v-model="formData.comment" style="width: 220px; padding-top: 5px;"></el-switch>
          </field>
        </div>
        <field label="佣金设置：" :asterisk="true" labelWidth="198px" :validation="validation.brokerageFirst">
          <div>
            <el-input v-model="formData.brokerageFirst" style="width: 200px; margin-right: 20px;"></el-input>
            <el-input v-model="formData.brokerageSecond" style="width: 200px; margin-right: 20px;"></el-input>
            <el-input v-model="formData.brokerageThird" style="width: 200px;"></el-input>
          </div>
        </field>
        <div class="fieldContainer">
          <field label="是否显示购物车：" :asterisk="true" labelWidth="198px" :validation="validation.showCart">
            <el-radio-group v-model="formData.showCart" @change="changeCar">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
          </field>
          <field label="是否显示销量：" :asterisk="true" labelWidth="198px" :validation="validation.showSalesNum">
            <el-radio-group v-model="formData.showSalesNum">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
          </field>
        </div>
        <!-- salesStatus 1 表示上架，0表示下架，2表示未上架 -->
        <field label="上架方式：" :asterisk="true" labelWidth="198px" :validation="validation.onSale">
          <el-radio-group v-model="salesStatus">
            <el-radio :label="2">自动上架</el-radio>
            <el-radio :label="1">立即上架</el-radio>
            <el-radio :label="0">暂不上架</el-radio>
          </el-radio-group>
          <el-date-picker
            style="margin-top: 10px"
            v-show="salesStatus === 2"
            v-model="formData.onSaleTime"
            type="datetime"
            placeholder="请输入上架时间"
          ></el-date-picker>
        </field>
      </div>

      <div class="card">
        <div class="title">商品详情</div>
        <div class="fieldContainer">
          <field label="商品类目：" :asterisk="true" labelWidth="198px" :validation="validation.category">
            <el-cascader
              v-model="formData.category"
              :options="categories"
              :props="{ checkStrictly: true }"
              clearable
              filterable
            ></el-cascader>
          </field>
          <field label="商品板块：" :asterisk="true" labelWidth="198px" :validation="validation.plate">
            <el-cascader
              v-model="formData.plate"
              :options="plates"
              :props="{ value: 'id', label: 'name', checkStrictly: true }"
              clearable
              filterable
            ></el-cascader>
          </field>
        </div>
        <field label="商品属性：" labelWidth="198px">
          <div>
            <div class="attr" v-for="(item, index) in formData.attributeValues" :key="item.id">
              <el-input v-model="item.attributeName" placeholder="请输入商品属性值"></el-input>
              <el-input v-model="item.attributeValue" placeholder="请输入商品描述"></el-input>
              <el-button @click="delAttr(index)" type="danger" plain>删除</el-button>
            </div>
            <el-button @click="addAttr" type="primary" plain>
              <i class="el-icon-plus"></i>
              新建属性
            </el-button>
          </div>
        </field>
        <div class="fieldContainer">
          <field label="仓库选择：" :asterisk="true" labelWidth="198px" :validation="validation.wareHouse">
            <el-select v-model="formData.wareHouse" placeholder="请选择仓库" style="width: 220px">
              <el-option v-for="item in warehouses" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </field>
          <field label="商品排序：" :asterisk="true" labelWidth="198px" :validation="validation.sortWeight">
            <el-input-number v-model="formData.sortWeight" :min="1" style="width: 220px;"></el-input-number>
          </field>
        </div>
        <div class="fieldContainer">
          <field label="商品热词：" labelWidth="198px" :validation="validation.hotWord">
            <el-input v-model="formData.hotWord" placeholder="请输入商品热词" style="width: 220px;"></el-input>
          </field>
          <field label="热词背景颜色：" labelWidth="198px">
            <el-select v-model="formData.color">
              <el-option label="默认" value="1"></el-option>
              <el-option label="灰绿" value="2"></el-option>
              <el-option label="绿色" value="3"></el-option>
              <el-option label="紫色" value="4"></el-option>
              <el-option label="蓝色" value="5"></el-option>
              <el-option label="黄色" value="6"></el-option>
            </el-select>
          </field>
        </div>
        <div class="fieldContainer">
          <field label="商品标签：" labelWidth="198px">
            <el-input v-model="formData.label" placeholder="请输入商品标签" style="width: 220px"></el-input>
          </field>
          <field label="虚拟已购数量：" :asterisk="true" labelWidth="198px" :validation="validation.initSaleNum">
            <el-input v-model="formData.initSaleNum" placeholder="请输入虚拟已购数量" style="width: 220px;">
              <template slot="append">件</template>
            </el-input>
          </field>
        </div>
        <div class="fieldContainer">
          <field label="退回库存时间：" :asterisk="true" labelWidth="198px" :validation="validation.backToCartTime">
            <el-input-number
              v-model="formData.backToCartTime"
              :controls="false"
              :min="0"
              style="width: 220px"
              aria-placeholder="请输入退回库存时间"
            ></el-input-number>
          </field>
        </div>
      </div>

      <div class="card">
        <div class="title">规格信息</div>

        <SpecsForm
          @skuChange="handleSkuChange"
          ref="specForm"
          :specs="specs"
          :specFormData="skus"
          :first="formData.brokerageFirst"
          :second="formData.brokerageSecond"
          :third="formData.brokerageThird"
        />
      </div>
      <div class="card">
        <div class="title">商品描述</div>
        <div id="editor"></div>
        <div>
          <label>视频上传</label>
          <el-upload
            :action="baseUrl"
            accept="video/*"
            list-type="text"
            :show-file-list="false"
            :file-list="uploadVideoFileList"
            :headers="headers"
            :on-success="onUploadVideoSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div v-for="item in uploadVideoFileList" :key="item.url" class="url">
            <div class="url-text">{{ item.name }}</div>
            <i class="el-icon-document" v-clipboard:copy="`<video src=${item.url}></video>`" v-clipboard:success="onCopy"></i>
          </div>
        </div>
      </div>

      <div class="footer">
        <el-button @click="onCancel">取消</el-button>
        <el-button @click="onSubmit" type="primary">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import WangEditor from 'wangeditor'
import moment from 'moment'
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as goodService from '@/api/good'
import * as warehouseService from '@/api/warehouse'
import * as specService from '@/api/spec'
import * as categoryService from '@/api/category'
import * as plateService from '@/api/plate'
import { baseURL } from '@/config'
import * as R from 'ramda'
import SpecsForm from './specsForm'

const init = {
  name: '',
  imgUrls: [],
  videoUrls: [],
  productCode: '000000',
  brokerageFirst: '0',
  brokerageSecond: '0',
  brokerageThird: '0',
  unit: 'GRAM',
  showUnit: '件',
  weight: 1,
  minBuyNum: 1,
  showCart: true,
  showSalesNum: true,
  onSale: true,
  onSaleTime: null,
  category: '',
  plate: null,
  attributeValues: [],
  wareHouse: '',
  sortWeight: 1,
  hotWord: '',
  backToCartTime: '30',
  initSaleNum: 0,
  maxPrice: null,
  stock: null,
  skus: [],
  detail: '',
  imageHideArray: '', // 需要隐藏的商品图片, 用,分割
  limitNumber: null, // 单次限购数量.
  costPrice: null, // 成本价
  memberPrice: null, // 会员价
  superMemberPrice: null, // 超级会员价
  freeShipNum: null,
  comment: false, // 是否开启评论
  label: '', // 商品标签
  color: '1'
}

export default {
  components: {
    SpecsForm
  },
  props: {
    id: {
      type: Number,
      required: false
    }
  },
  mixins: [
    validation({
      rules: {
        name: [v.required('请输入商品名称')],
        imgUrls: [v.required('请选择商品图片')],
        productCode: [v.required('请速入商品货号')],
        brokerageFirst: [v.required('请输入佣金')],
        unit: [v.required('请输入商品单位')],
        minBuyNum: [v.required('请输入起订数量')],
        category: [v.required('请输入类目')],
        plate: [v.required('请选择板块')],
        wareHouse: [v.required('请选择仓库')],
        backToCartTime: [v.required('请选择退回仓购物车时间')],
        initSaleNum: [v.required('请输入虚拟已购数量'), v.isInteger()],
        costPrice: [v.required('请输入成本价')],
        memberPrice: [v.required('请输入会员价')],
        superMemberPrice: [v.required('请输入超级会员价')]
      },
      field: 'formData'
    })
  ],
  mounted () {
    this.$nextTick(() => {
      const editor = new WangEditor('#editor')
      // 配置 onchange 回调函数，将数据同步到 vue 中

      // 上传图片
      editor.config.customUploadImg = (files, insert) => {
        this.uploadFn(files, insert)
      }
      // 上传视频
      // editor.config.customUploadVideo = (files, insert) => { this.uploadFn(files, insert) }
      editor.config.uploadImgShowBase64 = false // base 64 存储图片
      editor.config.uploadImgMaxSize = 8 * 1024 * 1024 * 10 // 将图片大小限制为 2M
      editor.config.uploadImgMaxLength = 10 // 限制一次最多上传 10张图片
      editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      editor.config.uploadImgServer = this.baseUrl // 配置服务器端地址
      editor.config.uploadImgHeaders = this.headers // 自定义 header
      editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名

      editor.config.onchange = newHtml => {
        this.editorData = newHtml
      }
      // 创建编辑器
      editor.create()
      this.editor = editor
    })
  },
  async created () {
    await Promise.all([this.fetchWareHouses(), this.fetchCategories(), this.fetchPlates(), this.fetchSpecs()])
    if (this.id) {
      this.fetchProductDetail()
      this.isEditPage = true
    }
  },
  data () {
    return {
      baseUrl: `${baseURL}/api/file/v1/ajh`,
      editor: null,
      editorData: '',
      checkList: [],
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      warehouses: [],
      categories: [],
      plates: [],
      formData: R.clone(init),
      specs: [],
      specForm: [],
      skus: [],
      imgFileList: [],
      videoFileList: [],
      uploadVideoFileList: [],
      chooseSpec: false,
      isEditPage: false,
      canEditSpec: false,
      salesStatus: 0,
      basicUnit: ['件', '包', '盒', '套', '双'],
      dialogVisible: true
    }
  },
  methods: {
    changeCar (v) {
      if (v) {
        this.formData.freeShipNum = null
      }
    },
    fmt (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    async uploadFn (files, insert) {
      await Promise.all(files.map(o => this.uploadOne(o, insert)))
    },
    async uploadOne (f, insert) {
      const httpRequest = new XMLHttpRequest()
      httpRequest.open('POST', this.baseUrl, false)
      httpRequest.setRequestHeader('Authorization', this.headers.Authorization)
      const file = new FormData()
      file.append('file', f)
      httpRequest.send(file)
      const res = httpRequest.response
      const resData = JSON.parse(res)
      const url = resData.body.data.url
      insert(url)
    },
    async fetchProductDetail () {
      const detail = await goodService.detail(this.id)
      const brokerageStrings = detail.brokerageString.split(',')
      this.imgFileList = detail.imgUrls.map(o => ({ name: '', url: o }))
      this.videoFileList = detail.videoUrls.map((o, idx) => ({
        name: `${idx + 1}.mp4`,
        url: o
      }))
      // this.editorData = detail.detail
      this.editor.txt.html(detail.detail)
      this.formData = {
        name: detail.name,
        imgUrls: detail.imgUrls,
        videoUrls: detail.videoUrls,
        productCode: detail.productCode,
        brokerageFirst: this._pennyToYuan(brokerageStrings[0]),
        brokerageSecond: this._pennyToYuan(brokerageStrings[1]),
        brokerageThird: this._pennyToYuan(brokerageStrings[2]),
        unit: detail.unit,
        showUnit: detail.showUnit,
        weight: detail.weight,
        minBuyNum: detail.minBuyNum,
        showCart: detail.showCart,
        showSalesNum: detail.showSalesNum,
        onSale: detail.onSale,
        onSaleTime: detail.onSaleTime,
        category: detail.category.parent ? [detail.category.parent.id, detail.category.id] : [detail.category.id],
        plate: detail.plate.pid ? [detail.plate.pid, detail.plate.id] : [detail.plate.id],
        attributeValues: detail.attributeValues,
        wareHouse: detail.wareHouse.id,
        sortWeight: detail.sortWeight,
        hotWord: detail.hotWord,
        backToCartTime: detail.backToCartTime,
        initSaleNum: detail.initSaleNum,
        maxPrice: detail.maxPrice,
        stock: detail.stock,
        detail: detail.detail,
        skus: detail.skus,
        lastModifiedDate: detail.lastModifiedDate,
        imageHideArray: detail.imageHideArray,
        limitNumber: detail.limitNumber,
        costPrice: this._pennyToYuan(detail.costPrice),
        memberPrice: this._pennyToYuan(detail.memberPrice),
        superMemberPrice: this._pennyToYuan(detail.superMemberPrice),
        freeShipNum: detail.freeShipNum,
        comment: !!detail.comment,
        label: detail.label,
        color: detail.color
      }
      this.skus = detail.skus
      this.salesStatus = detail.salesStatus
    },
    async fetchWareHouses () {
      try {
        const res = await warehouseService.list()
        this.warehouses = res
        if (!this.isEditPage && Array.isArray(this.warehouses) && this.warehouses.length > 0) {
          this.formData.wareHouse = this.warehouses[0].id
        }
      } catch (err) {
        // handle error
      }
    },
    async fetchSpecs () {
      try {
        const res = await specService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.items.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.specs = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchCategories () {
      try {
        const res = await categoryService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: R.isEmpty(item.children) ? null : item.children.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.categories = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchPlates () {
      try {
        const res = await plateService.list()
        const newData = res.list.map(o => {
          o.children = R.isEmpty(o.children) ? null : o.children
          return o
        })
        this.plates = newData
      } catch (err) {
        // handle error
      }
    },
    async customUpload (raw) {
      const res = await this.uploadOneImg(raw.file)
      const { url, realName } = res
      this.imgFileList.push({ name: realName, url })
      this.formData.imgUrls.push(url)
    },
    async uploadOneImg (f) {
      const httpRequest = new XMLHttpRequest()
      httpRequest.open('POST', this.baseUrl, false)
      httpRequest.setRequestHeader('Authorization', this.headers.Authorization)
      const file = new FormData()
      file.append('file', f)
      httpRequest.send(file)
      const res = httpRequest.response
      const resData = JSON.parse(res)
      return resData.body.data
    },
    handleSkuChange (data) {
      this.canEditSpec = !R.isEmpty(data)
    },
    onVideoSuccess (res) {
      const { url } = res.body.data
      this.formData.videoUrls.push(url)
    },
    onUploadVideoSuccess (res) {
      const { url, realName } = res.body.data
      this.uploadVideoFileList.push({ name: realName, url })
    },
    onCopy () {
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    async onSubmit () {
      await this.$validate()
      const res = this.$refs.specForm.validate()
      if (!res) {
        this.$notify.error('请填写正确规格信息')
        return
      }
      if (!this.editor.txt.html().trim()) {
        this.$notify.error('请填写商品详情')
        return
      }
      try {
        this.skus = this.$refs.specForm.getData()
        let postData = {
          name: this.formData.name,
          imgUrls: this.formData.imgUrls,
          videoUrls: this.formData.videoUrls,
          productCode: this.formData.productCode,
          category: {
            id: R.last(this.formData.category)
          },
          unit: this.formData.unit,
          showUnit: this.formData.showUnit,
          minBuyNum: this.formData.minBuyNum,
          brokerage: [
            parseInt(this._yuanToPenny(this.formData.brokerageFirst)),
            parseInt(this._yuanToPenny(this.formData.brokerageSecond)),
            parseInt(this._yuanToPenny(this.formData.brokerageThird))
          ],
          showCart: this.formData.showCart,
          backToCartTime: this.formData.backToCartTime,
          showSalesNum: this.formData.showSalesNum,
          sortWeight: this.formData.sortWeight,
          hotWord: this.formData.hotWord,
          initSaleNum: this.formData.initSaleNum,
          onSale: this.salesStatus !== 0,
          onSaleTime: this.salesStatus === 2 ? this.fmt(this.formData.onSaleTime) : null,
          wareHouse: {
            id: this.formData.wareHouse
          },
          plate: {
            id: R.last(this.formData.plate)
          },
          maxPrice: this.formData.maxPrice,
          stock: this.formData.stock,
          weight: parseInt(this.formData.weight),
          detail: this.editor.txt.html(),
          attributeValues: this.formData.attributeValues,
          imageHideArray: this.formData.imageHideArray,
          limitNumber: Number(this.formData.limitNumber) ? Number(this.formData.limitNumber) : null,
          costPrice: parseInt(this._yuanToPenny(this.formData.costPrice)),
          memberPrice: parseInt(this._yuanToPenny(this.formData.memberPrice)),
          superMemberPrice: parseInt(this._yuanToPenny(this.formData.superMemberPrice)),
          freeShipNum: this.formData.freeShipNum,
          comment: this.formData.comment,
          label: this.formData.label,
          color: this.formData.color
        }

        if (this.canEditSpec) {
          postData = R.omit(['maxPrice', 'stock'], postData)
          const sendSkus = R.clone(this.skus)
          sendSkus.forEach(item => {
            item.price = this._yuanToPenny(item.price)
          })
          postData.skus = sendSkus
        }
        if (this.isEditPage) {
          postData.modifiedDate = new Date(this.formData.lastModifiedDate).getTime()
          await goodService.update(this.id, postData)
        } else {
          await goodService.create(postData)
        }
        this.$notify.success({ title: '保存成功!' })
        this.onCancel()
      } catch (err) {
        const message = v.errorMessage(err) || '保存失败，请检查商品信息'
        this.$notify.error(message)
      }
    },
    onCancel () {
      this.$emit('cancel')
    },
    handleUpImage (file) {
      const index = this.imgFileList.findIndex(o => o.url === file.url)
      if (index !== 0) {
        this.imgFileList = this.swapArr(this.imgFileList, index, index - 1)
      }

      const uIndex = this.formData.imgUrls.findIndex(o => o === file.url)
      if (uIndex !== 0) {
        this.formData.imgUrls = this.swapArr(this.formData.imgUrls, uIndex, uIndex - 1)
      }
    },
    handleRemove (file) {
      this.imgFileList = this.imgFileList.filter(o => o.url !== file.url)
      this.formData.imgUrls = this.formData.imgUrls.filter(o => o !== file.url)
    },
    handleVideoRemove (file, fileList) {
      const url = file.url || file.response.body.data.url
      this.videoFileList = this.videoFileList.filter(o => o.url !== url)
      this.formData.videoUrls = this.formData.videoUrls.filter(o => o !== url)
    },
    addAttr () {
      this.formData.attributeValues.push({
        attributeName: '',
        attributeValue: ''
      })
    },
    delAttr (index) {
      this.formData.attributeValues.splice(index, 1)
    },
    swapArr (arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    }
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  height: 90vh;
  overflow-y: auto;
  .el-dialog__body {
    padding: 0;
  }
  .footer {
    height: 60px;
    line-height: 60px;
    text-align: right;
    background-color: #fff;
    padding: 0 20px;
    position: sticky;
    bottom: 0;
    z-index: 10002;
    left: 0;
    right: 0;
  }
}
.container {
  text-align: left;
}

.card {
  background: #ffffff;
  padding: 40px;
  margin: 0 auto 10px;
}

.fieldContainer {
  display: grid;
  grid-template-columns: 418px 418px;
}

.thirdColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.attr {
  display: grid;
  grid-template-columns: 1fr 2.5fr 0.5fr;
  grid-gap: 20px;
  margin-bottom: 10px;
}

.title {
  color: #5b5b5bff;
  font-size: 20px;
  margin-bottom: 24px;

  &:before {
    content: "";
    background: #1d90fbff;
    width: 4px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
  }
}

.plateItem {
  margin-bottom: 10px;
}

#editor {
  /deep/.w-e-text-container {
    height: 600px !important;
  }
}

.url {
  border: 1px solid #eeeeee;
  padding: 5px;
  color: #999999;
  display: grid;
  grid-template-columns: auto 20px;
  width: 100%;
  margin: 20px auto;
  &-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.videos {
  margin: 10px auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
