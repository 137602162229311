<template>
  <div>
    <div v-show="!editable">
      <span>{{ num }}</span
      ><i class="iconfont icon" @click="handEdit">&#xe603;</i>
    </div>
    <input v-show="editable" v-model="val" style="width: 50px;" type="number" @blur="onBlur" @keydown="handleInput" />
  </div>
</template>

<script>
export default {
  props: {
    num: Number
  },
  data () {
    return {
      val: '',
      editable: false
    }
  },
  methods: {
    handEdit () {
      this.val = this.num.toString()
      this.editable = true
    },
    onBlur () {
      this.editable = false
    },
    handleInput (e) {
      if (e.keyCode === 13) {
        this.$emit('onChange', e.target.value)
        this.editable = false
      }
    }
  }
}
</script>

<style scoped lasng="scss">
.icon {
  margin-left: 10px;
  cursor: pointer;
}
</style>
