<template>
  <div class="container">
    <div class="header">
      <div class="searchContainer">
        <div class="search">
          <div class="search-item">
            <div class="search-item-name">商品名称：</div>
            <el-input
              placeholder="请输入商品名称"
              prefix-icon="el-icon-search"
              v-model="search.name"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">货号：</div>
            <el-input
              placeholder="请输入货号"
              prefix-icon="el-icon-search"
              @keydown.native.enter="handleSearch"
              v-model="search.productCode"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">有购物车：</div>
            <el-select clearable v-model="search.showCart" @change="handleSearch">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
      </div>
      <div class="search" style="margin-top: 20px;">
        <div class="search-item">
          <div class="search-item-name">商品类目：</div>
          <el-cascader
            :options="categories"
            :props="{ checkStrictly: true }"
            v-model="search.categoryIds"
            @change="handleSearch"
            clearable
            filterable
          >
          </el-cascader>
        </div>
        <div class="search-item">
          <div class="search-item-name">商品板块：</div>
          <el-cascader
            style="min-width: 200px;"
            :options="plates"
            :props="{ checkStrictly: true }"
            v-model="search.plateIds"
            @change="handleSearch"
            clearable
            filterable
          >
          </el-cascader>
        </div>
        <div class="search-item">
          <div class="search-item-name">下架时间：</div>
          <el-date-picker
            v-model="search.offlineTime"
            type="daterange"
            @change="handleSearch"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="search" style="margin-top: 20px;">
        <div class="search-item">
          <div class="search-item-name">上架时间：</div>
          <el-date-picker
            v-model="search.onSaleTime"
            type="daterange"
            @change="handleSearch"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-button type="primary" plain @click="batchUp">批量上架</el-button>
        <el-button type="primary" plain @click="batchDown">批量下架</el-button>
        <el-button type="danger" plain @click="batchDelete">删除</el-button>
      </div>
    </div>

    <div class="content">
      <Tabs @onChange="handleTabChange" :active="search.salesStatus" />
      <el-table ref="multipleTable" :data="products" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" min-width="260" label="商品信息">
          <template slot-scope="scope">
            <div class="good">
              <img v-if="scope.row.mainImgUrl" :src="scope.row.mainImgUrl" alt="" />
              <div class="info">
                <EditName v-model="scope.row.name" :id="scope.row.id"></EditName>
                <div class="info-id">ID: {{ scope.row.id }}</div>
                <div class="info-code">货号：{{ scope.row.productCode }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="售价信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>￥{{ _pennyToYuan(scope.row.price) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="salesStatus" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ statusMap[scope.row.salesStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="category.name" label="类目" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="plate.name" label="板块" show-overflow-tooltip> </el-table-column>
        <el-table-column show-overflow-tooltip>
          <template v-slot:header>
            <span>销量</span>
            <i
              v-if="orderByValue !== 'realSaleNum'"
              @click="changeOrder(3)"
              class="el-icon-d-caret"
              style="cursor: pointer;"
            ></i>
            <i
              v-if="orderByValue === 'realSaleNum' && ascValue === 'desc'"
              @click="changeOrder(4)"
              class="el-icon-caret-bottom"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
            <i
              v-if="orderByValue === 'realSaleNum' && ascValue === 'asc'"
              @click="changeOrder(0)"
              class="el-icon-caret-top"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
          </template>
          <template slot-scope="scope">
            {{ scope.row.saleNum - scope.row.initSaleNum }}
          </template>
        </el-table-column>
        <el-table-column prop="stock" show-overflow-tooltip>
          <template v-slot:header>
            <span>库存</span>
            <i
              v-if="orderByValue !== 'stock'"
              @click="changeOrder(1)"
              class="el-icon-d-caret"
              style="cursor: pointer;"
            ></i>
            <i
              v-if="orderByValue === 'stock' && ascValue === 'desc'"
              @click="changeOrder(2)"
              class="el-icon-caret-bottom"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
            <i
              v-if="orderByValue === 'stock' && ascValue === 'asc'"
              @click="changeOrder(0)"
              class="el-icon-caret-top"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
          </template>
        </el-table-column>
        <el-table-column prop="saleNumber" label="商品排序" show-overflow-tooltip>
          <template slot-scope="scope">
            <SortWeight :num="scope.row.sortWeight" @onChange="v => handleSortWeightChange(v, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="添加时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ fmt(scope.row.createdDate) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="operation" min-width="100" label="操作">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
              <el-button type="text" @click="up(scope.row.id)" v-show="scope.row.salesStatus !== 1">上架</el-button>
              <el-button type="text" @click="down(scope.row.id)" v-show="scope.row.salesStatus === 1">下架</el-button>
              <el-button type="text" @click="deleteGood(scope.row.id)" v-show="scope.row.salesStatus !== 1" style="color: red"
                >删除</el-button
              >
              <el-button type="text" @click="detail(scope.row.id)">商品详情</el-button>
              <el-button type="text" @click="comment(scope.row.id)">评价列表</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <Edit v-if="showEdit" :id="currentId" @cancel="cancelEdit"></Edit>
    <Detail v-if="showDetail" :id="currentId" @cancel="showDetail = false"></Detail>
    <Comment v-if="showComment" :id="currentId" @cancel="showComment = false"></Comment>
  </div>
</template>

<script>
import moment from 'moment'
import Tabs from './tabs'
import * as goodService from '../../api/good.js'
import * as categoryService from '@/api/category'
import * as plateService from '@/api/plate'
import * as R from 'ramda'
import SortWeight from './sortWeight'
import EditName from './editName'
import Edit from './edit'
import Detail from './detail.vue'
import Comment from './comment.vue'

export default {
  components: {
    Tabs,
    SortWeight,
    EditName,
    Edit,
    Detail,
    Comment
  },
  data () {
    return {
      search: {
        salesStatus: null,
        name: null,
        showCart: null,
        productCode: null,
        categoryIds: null,
        plateIds: null,
        offlineTime: null,
        onSaleTime: null
      },
      statusMap: {
        1: '已上架',
        0: '已下架',
        2: '未上架'
      },
      categories: [],
      plates: [],
      products: [],
      selected: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      pageOptions: [10, 20, 50, 100, 200],
      currentId: null,
      orderByValue: 'sortWeight',
      ascValue: 'desc',
      showEdit: false, // 是否显示编辑窗口
      showDetail: false, // 是否显示详情的窗口
      showComment: false // 是否显示评价列表弹框
    }
  },
  created () {
    this.fetchList()
    this.fetchCategories()
    this.fetchPlates()
  },
  methods: {
    fmt (date) {
      return moment(date).format('YYYY年MM月DD日')
    },
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fmtDateStart (date) {
      return moment(date).format('YYYY-MM-DD 00:00:00')
    },
    fmtDateEnd (date) {
      return moment(date).format('YYYY-MM-DD 23:59:59')
    },
    async fetchList (page = this.currentPage, size = this.pageSize) {
      const query = {
        page: page - 1,
        size,
        salesStatus: this.search.salesStatus,
        name: this.search.name,
        productCode: this.search.productCode,
        categoryIds: this.search.categoryIds ? R.last(this.search.categoryIds) : null,
        showCart: this.search.showCart,
        plateIds: this.search.plateIds ? R.last(this.search.plateIds) : null,
        offlineTime: this.search.offlineTime
          ? [this.fmtDateStart(this.search.offlineTime[0]), this.fmtDateEnd(this.search.offlineTime[1])]
          : null,
        onSaleTime: this.search.onSaleTime
          ? [this.fmtDateStart(this.search.onSaleTime[0]), this.fmtDateEnd(this.search.onSaleTime[1])]
          : null,
        sort: `${this.orderByValue},${this.ascValue}`
      }
      const res = await goodService.list(query)
      this.total = res.totalElements
      this.products = res.list
    },
    async fetchCategories () {
      try {
        const res = await categoryService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.children.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.categories = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchPlates () {
      try {
        const res = await plateService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.children.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.plates = newData
      } catch (err) {
        // handle error
      }
    },
    async handleSortWeightChange (val, row) {
      try {
        await goodService.sortById(row.id, {
          sortWeight: parseInt(val),
          lastModifiedDate: new Date(row.lastModifiedDate).getTime()
        })
        await this.fetchList()
      } catch (err) {
        // handle error
      }
    },
    handleSearch () {
      this.currentPage = 1
      this.fetchList()
    },
    handleCreate () {
      this.currentId = null
      this.showEdit = true
    },
    handleTabChange (tab) {
      this.search.salesStatus = tab.key
      this.currentPage = 1
      this.fetchList()
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    edit (id) {
      this.currentId = id
      this.showEdit = true
    },
    async up (id) {
      try {
        const postData = {
          onSale: true,
          goodsIds: [id]
        }
        await goodService.updateSaleStatus(postData)
        this.$notify.success({ title: '上架成功' })
        this.fetchList()
      } catch (err) {
        this.$notify.error({ title: '上架失败' })
      }
    },
    async down (id) {
      try {
        const postData = {
          onSale: false,
          goodsIds: [id]
        }
        await goodService.updateSaleStatus(postData)
        this.$notify.success({ title: '下架成功' })
        this.fetchList()
      } catch (err) {
        this.$notify.error({ title: '下架失败' })
      }
    },
    async batchUp () {
      try {
        const postData = {
          onSale: true,
          goodsIds: R.pluck('id', this.selected)
        }
        await goodService.updateSaleStatus(postData)
        this.$notify.success({ title: '上架成功' })
        this.fetchList()
      } catch (err) {
        // handle error
        this.$notify.error({ title: '上架失败' })
      }
    },
    async batchDown () {
      try {
        const postData = {
          onSale: false,
          goodsIds: R.pluck('id', this.selected)
        }
        await goodService.updateSaleStatus(postData)
        this.$notify.success({ title: '下架成功' })
        this.fetchList()
      } catch (err) {
        // handle error
        this.$notify.error({ title: '下架失败' })
      }
    },
    async batchDelete () {
      try {
        await goodService.batchDelete(R.pluck('id', this.selected))
        this.$notify.success({ title: '删除商品成功' })
        this.fetchList()
      } catch (err) {
        this.$notify.error({ title: '删除商品失败' })
      }
    },
    async deleteGood (id) {
      try {
        await goodService.batchDelete([id])
        this.$notify.success({ title: '删除商品成功' })
        await this.fetchList()
      } catch (err) {
        this.$notify.error({ title: '删除商品失败' })
      }
    },
    detail (id) {
      this.currentId = id
      this.showDetail = true
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.fetchList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchList()
    },
    cancelEdit () {
      this.fetchList()
      this.showEdit = false
    },
    comment (id) {
      this.currentId = id
      this.showComment = true
    },
    changeOrder (num) {
      switch (num) {
        case 0:
          this.orderByValue = 'sortWeight'
          this.ascValue = 'desc'
          break
        case 1:
          this.orderByValue = 'stock'
          this.ascValue = 'desc'
          break
        case 2:
          this.orderByValue = 'stock'
          this.ascValue = 'asc'
          break
        case 3:
          this.orderByValue = 'realSaleNum'
          this.ascValue = 'desc'
          break
        case 4:
          this.orderByValue = 'realSaleNum'
          this.ascValue = 'asc'
          break
      }
      this.fetchList()
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.header {
  padding: 30px;
  background-color: #ffffff;
}

.searchContainer {
  display: grid;
  grid-template-columns: auto 120px;
  grid-column-gap: 20px;
}

.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;

  &-item {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
  }
}

.operation {
  margin-top: 20px;
}

.content {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;

  &-tab {
    padding-left: 20px;
  }
}

.good {
  display: grid;
  grid-template-columns: 80px 180px;
  grid-column-gap: 10px;

  & > img {
    display: block;
    width: 80px;
    height: 80px;
  }

  .info {
    font-size: 12px;
    &-name {
      font-weight: 600;
      color: #7d7e8e;
    }

    &-id {
      margin-top: 20px;
      font-weight: 500;
      color: #7d7e8e;
    }
    &-code {
      font-weight: 500;
      color: #7d7e8e;
    }
  }
}
.pagination {
  margin-top: 30px;
  text-align: center;
}
</style>
