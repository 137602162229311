import axios from './axios'

export const list = params => axios.get('/management/goods', { params })
export const detail = id => axios.get(`/management/goods/${id}`)
// export const listNoPage = () => axios.get('/role/listNoPage', {})
export const sortById = (id, postData) => axios.put(`/management/goods/${id}/sequence`, null, { params: postData })
export const create = form => axios.post('/management/goods', form)
export const update = (id, form) => axios.put(`/management/goods/${id}`, form)
export const updateSaleStatus = (payload) => axios.patch('/management/goods/on-sale', payload)
export const batchDelete = ids => axios.delete('/management/goods', { params: { goodsIds: ids.join(',') } })
