<template>
  <el-dialog
    title="新增评价"
    append-to-body
    top="10vh"
    width="50%"
    :visible.sync="dialogVisible"
    @close="onCancel"
  >
    <el-form :model="formData" type="textarea" label-width="100px">
      <el-form-item label="用户昵称">
        <el-input v-model="formData.nickName"></el-input>
      </el-form-item>
      <el-form-item label="用户头像">
        <el-upload
          class="upload-box"
          :limit="1"
          :action="baseUrl"
          :headers="headers"
          accept="image/*"
          list-type="picture-card"
          :before-upload="beforeAvatarUpload"
          :on-remove="onAvatarRemove"
          :on-success="onAvatarSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="评论文字">
        <el-input v-model="formData.comment" type="textarea" row="4"></el-input>
      </el-form-item>
      <el-form-item label="评论图片">
        <el-upload
          class="upload-box"
          :action="baseUrl"
          :headers="headers"
          accept="image/*"
          list-type="picture-card"
          :on-remove="onImageRemove"
          :on-success="onImageSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="审核通过">
        <el-switch v-model="formData.status" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="addComment">添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'
import { dateFormat } from '@/utils/tools'
import { baseURL } from '@/config'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      baseUrl: `${baseURL}/api/file/v1/ajh`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      dialogVisible: true,
      imgList: [],
      formData: {
        nickName: '',
        imgUrl: '',
        comment: '',
        imgList: '',
        status: 1,
        createdDate: '',
        goodsId: ''
      }
    }
  },
  methods: {
    async addComment () {
      try {
        this.formData.goodsId = this.id.toString()
        this.formData.createdDate = dateFormat(new Date())
        this.formData.imgList = this.imgList.join(',')
        await axios.post('/management/goods/comment', this.formData)
        this.$notify.success('添加成功！')
        this.$emit('confirm')
      } catch (error) {
        this.$notify.error('添加失败！')
      }
    },
    beforeAvatarUpload (file) {
      if (file.size > 1024 * 512) {
        this.$message.error('上传头像图片大小不能超过 500KB!')
        return false
      }
    },
    onAvatarRemove () {
      this.formData.imgUrl = ''
    },
    onAvatarSuccess (res) {
      const { url } = res.body.data
      this.formData.imgUrl = url
    },
    onImageRemove (file) {
      const url = file.url
      const index = this.imgList.findIndex(item => item === url)
      this.imgList.splice(index, 1)
    },
    onImageSuccess (res) {
      const { url } = res.body.data
      this.imgList.push(url)
    },
    onCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  height: 80vh;
  overflow-y: auto;
}
.avator {
  width: 100px;
}
</style>
