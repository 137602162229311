<template>
<el-dialog title="商品详情" :modal-append-to-body="false" :close-on-click-modal="false" top="20px" width="80%" height="calc(80vh)" :visible.sync="dialogVisible" @close="onCancel">
  <div class="container">
    <div class="card">
      <div class="title">商品信息</div>
      <div class="field">
        <div class="field-label">商品名称：</div>
        <div class="field-value">{{ data.name }}</div>
      </div>
      <div class="field">
        <div class="field-label">商品图片：</div>
        <div class="field-value">
          <el-image
            v-for="item in data.imgUrls"
            :key="item"
            :src="item"
            :preview-src-list="data.imgUrls"
            :z-index="9999"
            style="width: 100px; height: 100px"
          >
          </el-image>
        </div>
      </div>
      <div class="field">
        <div class="field-label">上传视频：</div>
        <div class="field-value">
          <span @click="play" v-show="data.videoUrls && data.videoUrls.length !== 0">1.mp4</span>
          <div v-if="showVideo" class="video">
            <video-player ref="videoPlayer" class="video-custom" :playsinline="true" :options="playerOptions" />
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-label">商品货号：</div>
        <div class="field-value">{{ data.productCode }}</div>
      </div>
      <!-- <div class="field">
        <div class="field-label">商品价格：</div>
        <div class="field-value">{{ data.price }}元</div>
      </div> -->
      <div class="field">
        <div class="field-label">商品单位：</div>
        <div class="field-value">
          {{ data.unit === "PIECE" ? "千克" : "克" }}
        </div>
      </div>
      <div class="field">
        <div class="field-label">商品重量：</div>
        <div class="field-value">{{ data.weight }}</div>
      </div>
      <div class="field">
        <div class="field-label">起订数量：</div>
        <div class="field-value">{{ data.minBuyNum }}件</div>
      </div>
      <div class="field">
        <div class="field-label">佣金设置：</div>
        <div class="field-value">
          <span v-for="(o, index) in data.brokerageString.split(',')" :key="o + index">
            {{ _pennyToYuan(o) }}元<span v-if="index !== 2">，</span>
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field-label">是否显示购物车：</div>
        <div class="field-value">{{ data.showCart ? "显示" : "隐藏" }}</div>
      </div>
      <div class="field">
        <div class="field-label">是否显示销量：</div>
        <div class="field-value">{{ data.showSalesNum ? "显示" : "隐藏" }}</div>
      </div>
      <div class="field">
        <div class="field-label">上架状态：</div>
        <div class="field-value">
          {{ data.salesStatus === 0 ? "未上架" : data.salesStatus === 1 ? "已上架" : "自动上架" }}
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title">商品详情</div>
      <div class="field">
        <div class="field-label">商品类目：</div>
        <div class="field-value">{{ data.category.name }}</div>
      </div>
      <div class="field">
        <div class="field-label">商品板块：</div>
        <div class="field-value">{{ data.plate.name }}</div>
      </div>
      <div class="field">
        <div class="field-label">商品属性：</div>
        <div class="field-value">
          <div v-for="(item, index) in data.attributeValues" :key="item.id">
            <div class="field" style="margin: 0">
              <div class="field-label">属性{{ index + 1 }}：</div>
              <div class="field-value">{{ item.attributeName }}</div>
            </div>
            <div class="field" style="margin: 0">
              <div class="field-label">属性描述：</div>
              <div class="field-value">{{ item.attributeValue }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-label">仓库选择：</div>
        <div class="field-value">{{ data.wareHouse.name }}</div>
      </div>
      <div class="field">
        <div class="field-label">商品排序：</div>
        <div class="field-value">{{ data.sortWeight }}</div>
      </div>
      <div class="field">
        <div class="field-label">商品热词：</div>
        <div class="field-value">{{ data.hotWord }}</div>
      </div>
      <div class="field">
        <div class="field-label">退回库村时间：</div>
        <div class="field-value">{{ data.backToCartTime }}（分钟）</div>
      </div>
      <div class="field">
        <div class="field-label">虚拟已购数量：</div>
        <div class="field-value">{{ data.initSaleNum }}</div>
      </div>
    </div>
    <div class="card">
      <div class="title">规格信息</div>
      <div class="field">
        <div class="field-label">商品规格：</div>
        <div class="field-value">
          <el-table v-show="data.skus && data.skus.length !== 0" :data="data.skus" style="width: 100%">
            <el-table-column prop="spec" label="规格">
              <template slot-scope="scope">
                {{ getSpecName(scope.row.specItems) }}
              </template>
            </el-table-column>
            <el-table-column prop="code" label="编码" />
            <el-table-column label="价格">
              <template slot-scope="scope"> ￥{{ _pennyToYuan(scope.row.price) }} </template>
            </el-table-column>
            <el-table-column prop="weight" label="重量"> </el-table-column>
            <el-table-column prop="stock" label="库存"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="field">
        <div class="field-label">价格：</div>
        <div class="field-value">￥{{ _pennyToYuan(data.price) }}</div>
      </div>
      <div class="field">
        <div class="field-label">总库存：</div>
        <div class="field-value">{{ data.stock }}件</div>
      </div>
    </div>
    <div class="card">
      <div class="title">商品描述</div>
      <div v-html="data.detail"></div>
    </div>
  </div>
</el-dialog>
</template>

<script>
import * as goodService from '@/api/good'
import * as warehouseService from '@/api/warehouse'
import * as specService from '@/api/spec'
import * as categoryService from '@/api/category'
import * as plateService from '@/api/plate'

export default {
  props: {
    id: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      data: {
        category: {},
        wareHouse: {},
        plate: {},
        brokerageString: ''
      },
      warehouses: [],
      categories: [],
      plates: [],
      specs: [],
      showVideo: false,
      dialogVisible: true
    }
  },
  created () {
    this.fetchProductDetail()
    this.fetchWareHouses()
    this.fetchCategories()
    this.fetchPlates()
  },
  computed: {
    playerOptions () {
      if (this.data.videoUrls && this.data.videoUrls.length === 0) {
        return {}
      }
      return {
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.data.videoUrls[0]
          }
        ],
        hls: true,
        poster: 'http://pic33.nipic.com/20131007/13639685_123501617185_2.jpg', // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  methods: {
    play () {
      this.showVideo = true
    },
    async fetchProductDetail () {
      const detail = await goodService.detail(this.id)
      this.data = detail
    },
    async fetchWareHouses () {
      try {
        const res = await warehouseService.list()
        this.warehouses = res
      } catch (err) {
        // handle error
      }
    },
    async fetchSpecs () {
      try {
        const res = await specService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.items.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.specs = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchCategories () {
      try {
        const res = await categoryService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.children.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.categories = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchPlates () {
      try {
        const res = await plateService.list()
        this.plates = res.list
      } catch (err) {
        // handle error
      }
    },
    getSpecName (data) {
      const cur = data[0]
      return `${cur.name} - ${cur.spec.name}`
    },
    onCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  height: 90vh;
  overflow-y: auto;
}
.container {
  text-align: left;
}

.card {
  background: #ffffff;
  padding: 40px;
  margin: 10px auto;
}

.title {
  color: #5b5b5bff;
  font-size: 20px;
  margin-bottom: 24px;

  &:before {
    content: "";
    background: #1d90fbff;
    width: 4px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
  }
}

.field {
  color: #7d7e8e;
  font-size: 16px;
  display: flex;
  margin: 15px auto;
}

.video {
  width: 100%;
  /* height: 500px; */
}

.video-custom {
  width: 500px !important;
}
</style>
