<template>
  <div>
    <el-button type="primary" @click="addSpec" style="margin-right: 20px;">添加规格</el-button>
    <el-cascader
      v-model="selectedValue"
      v-show="showTable"
      :options="specs"
      :props="{ multiple: true }"
      @change="handleSpecChange"
      filterable
      clearable
    ></el-cascader>
    <table class="form" v-show="showTable && skus.length > 0">
      <tr>
        <th>规格</th>
        <th>
          <span>商品重量</span>
          <div>
            <el-input-number
              style="width: 100px;"
              v-model="weight"
              controls-position="right"
              size="small"
              :min="0"
            ></el-input-number>
            <el-button size="small" type="primary" @click="handleWeightClick">设置</el-button>
          </div>
        </th>
        <th>
          价格
          <div>
            <el-input-number
              style="width: 100px;"
              v-model="price"
              controls-position="right"
              size="small"
              :min="0"
            ></el-input-number>
            <el-button size="small" type="primary" @click="handlePriceClick">设置</el-button>
          </div>
        </th>
        <th>
          库存
          <div>
            <el-input-number
              style="width: 100px;"
              v-model="stock"
              controls-position="right"
              size="small"
              :min="0"
            ></el-input-number>
            <el-button size="small" type="primary" @click="handleStockClick">设置</el-button>
          </div>
        </th>
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in skus" :key="index">
        <td>
          <span v-for="item in skus[index].specs" :key="item.specItemId">
            {{ `${item.specName}-${item.specItemName}` }} <br />
          </span>
        </td>

        <td>
          <el-input-number v-model="skus[index].weight" placeholder="请输入重量" style="width: 150px;" :min="0">
            <template slot="append">克</template>
          </el-input-number>
        </td>
        <td>
          <el-input-number v-model="skus[index].price" placeholder="请输入价格" style="width: 150px;" :min="0">
            <template slot="prepend">¥</template>
          </el-input-number>
        </td>
        <td>
          <el-input-number v-model="skus[index].stock" placeholder="请输入总库存" style="width: 150px;" :min="0">
          </el-input-number>
        </td>
        <td>
          <el-button type="text" @click="deleteRow(index)">删除</el-button>
        </td>
      </tr>
    </table>

    <small v-if="hasError" class="errorMsg">{{ message }}</small>
  </div>
</template>

<script>
import * as R from 'ramda'

const initSpec = {
  // code: null,
  price: null,
  weight: null,
  stock: null,
  specs: []
}

const getSkus = data => {
  const res = []
  const getSku = (level = 0, val = null) => {
    if (level < data.length) {
      data[level].forEach(item => {
        const init = !val ? R.clone(initSpec) : R.clone(val)
        init.specs.push(item)
        if (level === data.length - 1) {
          res.push(init)
        }
        if (level < data.length - 1) {
          getSku(level + 1, init)
        }
      })
    }
  }

  getSku()
  return res
}

const getSelectedValue = (data, specs) => {
  const aggregate = data.map(o =>
    o.specItems.map(obj => {
      return [obj.spec.originId, obj.originId]
    })
  )
  const obj = {}
  aggregate.forEach(o => {
    o.forEach(item => {
      const key = `${item[0]}-${item[1]}`
      if (R.isNil(obj[key])) {
        obj[key] = true
      }
    })
  })
  const res = R.keys(obj).map(o => R.split('-', o).map(tar => parseInt(tar)))
  return res
}

export default {
  props: {
    specs: {
      type: Array,
      default () {
        return {}
      }
    },
    specFormData: {
      type: Array,
      default () {
        return {}
      }
    },
    first: {
      type: [String, Number],
      require: true
    },
    second: {
      type: [String, Number],
      require: true
    },
    third: {
      type: [String, Number],
      require: true
    }
  },
  data () {
    return {
      skus: [],
      selectedValue: [],
      showTable: false,
      hasError: false,
      message: '',
      weight: 0,
      price: 0,
      stock: 0
    }
  },
  watch: {
    specFormData: {
      deep: true,
      handler: function (curVal, oldVal) {
        // 只有初始化进入页面的时候才执行，因为保存失败也会触发变动，再保存规格就错误
        if (oldVal && Array.isArray(oldVal) && oldVal.length === 0) {
          const that = this
          this.skus = curVal.map(item => {
            return {
              code: item.code,
              price: that._pennyToYuan(item.price),
              weight: item.weight,
              stock: item.stock,
              specs:
                item.specItems &&
                item.specItems.map(o => {
                  return {
                    specName: o.spec.name,
                    specId: o.spec.originId,
                    specItemName: o.name,
                    specItemId: o.originId
                  }
                })
            }
          })
          if (!R.isEmpty(this.skus) && curVal[0].specItems) {
            this.selectedValue = getSelectedValue(curVal, this.specs)
            this.showTable = true
            this.$emit('skuChange', this.skus)
          }
        }
      }
    }
  },
  methods: {
    addSpec () {
      this.showTable = true
    },
    handleWeightClick () {
      this.skus = this.skus.map(o => {
        o.weight = this.weight
        return o
      })
    },
    handlePriceClick () {
      this.skus = this.skus.map(o => {
        o.price = this.price
        return o
      })
    },
    handleStockClick () {
      this.skus = this.skus.map(o => {
        o.stock = this.stock
        return o
      })
    },
    getLen (data) {
      const arr = []
      data.forEach(item => arr.push(item[0]))
      return R.length(R.dropRepeats(arr))
    },
    handleSpecChange (data) {
      const len = this.getLen(data)
      const existSkusMap = R.clone(this.skus).reduce((a, c) => {
        const keys = c.specs.map(o => o.specItemId)
        if (R.length(keys) === len) {
          a[keys.join('-')] = c
        }
        return a
      }, {})
      const specs = this.specs
      const all = data.map(item => {
        const curSpec = specs.find(o => o.value === item[0])
        const childSpec = curSpec.children.find(o => o.value === item[1])
        return {
          specName: curSpec.label,
          specId: curSpec.value,
          specItemName: childSpec.label,
          specItemId: childSpec.value
        }
      })
      const group = R.groupBy(R.prop('specId'), all)
      const newSkus = getSkus(Object.values(group))
      // 替换已存在的
      const newSkusMap = newSkus.reduce((a, c) => {
        const keys = c.specs.map(o => o.specItemId)
        if (R.length(keys) === len) {
          a[keys.join('-')] = c
        }
        return a
      }, {})
      // 删除的时候移除老的没有的
      for (const k in existSkusMap) {
        if (!newSkusMap[k]) {
          delete existSkusMap[k]
        }
      }
      this.skus = Object.values(Object.assign(newSkusMap, existSkusMap))
      this.$emit('skuChange', newSkus)
    },
    deleteRow (idx) {
      this.skus = this.skus.filter((_, index) => index !== idx)
      this.$emit('skuChange', this.skus)
    },
    validate () {
      const that = this
      let { hasError } = this.skus.reduce(
        (a, c) => {
          if (!c.weight || !c.price || (!c.stock && c.stock !== 0)) {
            a.hasError = true
          }
          if (c.price < Number(that.first) || c.price < Number(that.second) || c.price < Number(that.third)) {
            a.hasError = true
          }
          return a
        },
        { hasError: false }
      )

      if (this.skus.length === 0) {
        hasError = true
      }

      if (hasError) {
        this.hasError = true
        this.message = '规格信息错误'
        return false
      } else {
        this.hasError = false
        this.message = null
        return true
      }
    },
    getData () {
      return this.skus.map(o => R.omit(['id'], o))
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  width: 100%;
  text-align: center;
  margin: 10px auto;
  & > tr {
    & > th {
      background-color: #f5f5f5;
      padding: 10px 20px;
    }
    & > td {
      padding: 10px 20px;
      background-color: #f9f9f9;
    }
  }
}

.errorMsg {
  display: block;
  color: #f06c73;
  height: 20px;
}

.combine {
  display: grid;
  grid-template-columns: 20px 20px;
}
</style>
