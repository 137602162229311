<template>
  <div>
    <span v-if="!idEdit" class="info-name" @dblclick="showInput">{{ value }}</span>
    <el-input v-else v-model="value" @blur="changeName" @keydown.native.enter="$event.target.blur"></el-input>
  </div>
</template>

<script>
import axios from '@/api/axios'

export default {
  props: {
    value: {
      type: String,
      require: true
    },
    id: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      idEdit: false
    }
  },
  methods: {
    showInput () {
      this.idEdit = true
    },
    async changeName () {
      try {
        if (!this.value) {
          this.$notify.warning({ title: '请填写商品名称！' })
          return
        }
        await axios.put(`/management/goods/name/${this.id}?name=${this.value}`)
        this.idEdit = false
        this.$notify.success({ title: '修改成功！' })
      } catch (error) {
        this.$notify.error({ title: '修改失败！' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-name {
  font-weight: 600;
  color: #7d7e8e;
}
</style>
