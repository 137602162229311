<template>
  <div class="container">
    <span
      v-for="item in tabs"
      :key="item.key"
      class="tab"
      :class="{ 'tab-active': active === item.key }"
      @click="handleClick(item)"
    >
      {{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: [Number, null],
      required: false,
      default: null
    }
  },
  data () {
    return {
      tabs: [
        { name: '全部', key: null },
        { name: '已上架', key: 1 },
        { name: '未上架', key: 2 },
        { name: '已下架', key: 0 }
      ]
    }
  },
  methods: {
    handleClick (data) {
      this.$emit('onChange', data)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
.container {
  text-align: left;
  border-bottom: 1px solid $borderColor;
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  padding-left: 50px;
  box-sizing: border-box;
}

.tab {
  display: inline-block;
  line-height: 66px;
  color: #acadb7;
  font-weight: 500;
  font-size: 20px;
  margin-right: 70px;
  cursor: pointer;
  box-sizing: content-box;

  &-active {
    border-bottom: 4px solid #1d90fbff;
  }
}
</style>
