<template>
  <el-dialog
    append-to-body
    title="评价列表"
    top="10vh"
    width="70%"
    :visible.sync="dialogVisible"
    @close="onCancel"
  >
    <div class="container">
      <el-button type="primary" @click="addComment">添加</el-button>
      <el-table :data="list">
        <el-table-column border label="用户信息">
          <template slot-scope="scope">
            <div class="basic">
              <img :src="scope.row.imgUrl" />
              <div>
                <div>{{ scope.row.nickName }}</div>
                <div>ID：{{ scope.row.member ? scope.row.member.id : "无" }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评价时间">
          <template slot-scope="scope">
            <span>{{ dateFormat(scope.row.createdDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="评价文字" prop="comment"> </el-table-column>
        <el-table-column label="评价图片">
          <template slot-scope="scope" v-if="scope.row.imgList">
            <el-image
              v-for="o in scope.row.imgList.split(',')"
              fit="contain "
              :key="o"
              :src="o"
              :preview-src-list="scope.row.imgList.split(',')"
              :z-index="9999"
              class="commentImage"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.status" type="text" @click="approvalComment(scope.row.id, 1)">待发布</el-button>
            <el-button v-else type="text" @click="approvalComment(scope.row.id, 0)">已发布</el-button>
            <el-button type="text" @click="deleteComment(scope.row.id)" style="color: red;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <AddComment v-if="showAddComment" :id="id"  @cancel="showAddComment = false" @confirm="confirmComment"></AddComment>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'
import { dateFormat } from '@/utils/tools'
import AddComment from './addComent.vue'

export default {
  components: {
    AddComment
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialogVisible: true,
      total: 10,
      pageSize: 10,
      currentPage: 1,
      list: [],
      showAddComment: false
    }
  },
  created () {
    this.fetchComment()
  },
  methods: {
    dateFormat,
    async fetchComment () {
      const params = {
        goodsId: this.id,
        page: this.currentPage - 1,
        size: this.pageSize,
        sort: 'createdDate,desc'
      }
      const res = await axios.get('/management/goods/comment', { params })
      this.list = res.list
      this.total = res.totalElements
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchComment()
    },
    handleSizeChange (val) {
      this.currentPage = 1
      this.pageSize = val
      this.fetchComment()
    },
    async approvalComment (id, status) {
      try {
        await axios.put(`/management/goods/comment/${id}/audit`, { status })
        this.$notify.success('操作成功！')
        this.fetchComment()
      } catch (error) {
        this.$notify.success('操作失败！')
      }
    },
    async deleteComment (id) {
      try {
        await axios.delete(`/management/goods/comment/${id}`)
        this.$notify.success('删除成功！')
        this.fetchComment()
      } catch (error) {
        this.$notify.success('删除失败！')
      }
    },
    onCancel () {
      this.$emit('cancel')
    },
    addComment (id) {
      this.showAddComment = true
    },
    confirmComment () {
      this.currentPage = 1
      this.fetchComment()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  height: 80vh;
  overflow-y: auto;
}
.basic {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
.commentImage{
  padding: 6px;
  /deep/ img {
    max-width: 80px;
    max-height: 80px;
  }
}
.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
